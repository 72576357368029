.Success{
  img{
    width: 100%;
     max-width: 120px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  .contenedor-success{
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    flex-flow: row;
    align-items: center;
    justify-content: center;

    .col{
      width: 100%;
      flex: 1;
      h2{  
        margin-top: 1rem;
        font-weight: bolder !important;
      @include phone{
        font-size: 1rem !important;
      }}
      .texto{
        max-width: 420px;
        margin-left: 1rem;
        font-size: 1rem;
      }
      img{
        width: 100%;
         max-width: 500px;
      }
    }
    @include phone{
      grid-template-columns: 1fr ;
      flex-flow:column-reverse;
      .col{
        width: 100%;
      }
    }
  }
}