.Nosotros {
  padding: 1rem;
  h2 {
    text-transform: uppercase;
    font-size: 2rem;
  }
  .contenedor-nosotros {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    flex-flow: row;
    align-items: center;
    justify-content: center;

    .col {
      width: 100%;
      flex: 1;
      h2 {
        margin-top: 1rem;
        font-weight: bolder !important;
        @include phone {
          font-size: 1rem !important;
        }
      }
      .texto {
        max-width: 420px;
        margin-left: 1rem;
        font-size: 1rem;
      }
      img {
        width: 100%;
        max-width: 500px;
      }
    }
    @include phone {
      grid-template-columns: 1fr;
      flex-flow: column-reverse;
      .col {
        width: 100%;
      }
    }
  }
  .centro {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $app-2;
    color: white !important;
    width: 100%;
    h3 {
      margin: none !important;
    }
    padding: 1rem;
    box-sizing: border-box;
  }

  .Nosotros-mision {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 100%;
    margin: 2rem 0;
    .col {
      padding: 1rem;
      margin: 1rem;
      background-color: $app-1;
      border-radius: 1rem;
      height: 100%;
    }
    .titulo {
      color: $app-2 !important;
    }
    ul {
      margin: 1rem;
    }
    color: white;
    padding: 1rem;
    align-items: center;
    @include tablet {
      display: flex;
      flex-flow: column !important;
    }
  }
  .nosotros-valores {
    width: 100%;
    ul {
      margin-top: 2rem;
      list-style: none;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      li {
        padding: 1rem;
        background-color: $app-1;
        border-radius: 8px;
        color: white;
        text-align: center;
        font-weight: bold;
      }
      @include tablet {
        grid-template-columns: 1fr 1fr !important;
      }
      @include phone {
        grid-template-columns: 1fr !important;
      }
    }
  }
}
