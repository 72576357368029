.Servicios {
  display: flex;

  .servicios {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 1rem;
    .card-servicios {
      background-color: rgb(238, 238, 238);

      border-radius: 8px;
      padding: 1rem !important;
      box-sizing: border-box;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.192),
        0 10px 10px rgba(0, 0, 0, 0.144);
      margin-top: 1rem;
      align-items: center;
      display: flex;
      flex-flow: row;
      img {
        max-width: 180px;
        height: auto;

        @include phone {
          margin-top: 1rem;
          max-width: 100%;
        }
      }
      text-align: left;
      padding: 1rem;
      @include phone {
        flex-flow: column;
        text-align: center;
      }

      .contenedor-card {
        display: flex;
        flex-flow: column;
      }
    }
    @include phone {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }
  }
  .centro {
    font-weight: bolder;
    padding: 1rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
  }
}
