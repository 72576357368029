@import './mixins';
@import './variables';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;0,600;0,700;1,400&display=swap');

html {
  font-size: calc((1 - 1) * 1.2vw + 1rem);
} /*16px*/

p {
  margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 1rem 0 0.38rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 1.1;
}

h1 {
  font-size: calc((1 - 1) * 1.2vw + 2.3rem) !important;
}

h2 {
  font-size: calc((0.8) * 1vw + 1.4rem) !important;
  max-width: 680px;
  text-transform: uppercase;

  color: $app-2;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 0.9rem;
  margin: 0 !important;
}

h5 {
  font-size: 1.8rem;
  text-transform: uppercase;
  text-align: center;
}

small,
.text_small {
  font-size: 0.8rem;
}

@include phone {
  @import url('https://fonts.googleapis.com/css?family=Poppins:400');

  html {
    font-size: 100%;
  } /*16px*/

  p {
    margin-bottom: 1rem;
  }

  h1,
  h2,
  h4,
  h5 {
    margin: 3rem 0 1.02rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    line-height: 1.3;
  }

  h1 {
    text-align: left !important;
    margin: 1rem;
    font-size: calc((1 - 1) * 1.2vw + 1.2rem) !important;
  }

  h2 {
    text-align: left !important;
    margin: 1rem;
    font-size: calc((1 - 1) * 1.2vw + 1.2rem) !important;
  }

  h3 {
    font-size: 1.24rem;
  }

  h4 {
    font-size: 0.8rem;
    margin: 0 !important;
  }

  h5 {
    font-size: 1.125rem;
  }

  small,
  .text_small {
    font-size: 0.889rem;
  }
}
