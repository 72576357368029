.navbar {
  top: 0;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1% 9%;
  background-color: rgb(255, 255, 255);
  color: $app-1;

  width: 100%;
  z-index: 1;
  box-sizing: border-box;
  @include tablet {
  }
  .navbar-icon {
    display: none;

    padding: 0.3rem;
    @include tablet {
      display: block;
      font-size: 2rem;
    }
  }

  &_logo {
    img {
      max-height: 50px;
      max-width: auto;
    }
    @include phone {
      img {
        margin-top: 1rem;
        max-height: 40px;
        max-width: auto;
      }
    }
  }

  &_menu {
    position: relative;
    display: flex;
    list-style: none;
    max-width: 60vw;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-flow: row;

    a {
      font-weight: 600;
      color: $app-1;
      cursor: pointer;
      &:hover {
        li {
          color: $app-2 !important;
        }
      }
    }

    @include tablet {
      display: none;
    }
  }

  & .active {
    opacity: 1;
    top: 62px;
    left: 0px;
    z-index: -1;
    flex-flow: column;
    position: absolute;
    background-color: #004ead;
    height: calc(100vh - 62px);
    width: 100%;
    display: flex;
    align-items: center;
    list-style: none;
    justify-content: space-evenly;
    z-index: 0;
    font-size: 1.5rem;
    overflow: hidden;
    li {
      box-sizing: border-box;
    }
    a {
      text-align: center;
      font-weight: bold;
      padding: 1rem;
      width: 100%;
      height: auto;
      color: white !important;
    }
  }

  a {
    text-decoration: none;
  }
}

.dropdown-menu {
  display: flex;
  flex-flow: column;
  position: absolute;
  justify-content: center;
  list-style: none;
  top: 20px;

  z-index: 100;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  li {
    position: relative;
    background-color: rgb(230, 230, 230);
    cursor: pointer;

    &:hover {
      color: #862eeb;
    }
  }

  @include tablet {
    position: block;
    display: none;
  }
}

.dropdown.menu {
  display: none;
}

.dropdown-link {
  display: block;
  width: 100%;
  height: 100%;
  font-weight: normal;
  text-decoration: none;
  color: white;
  padding: 1rem 0.5rem;
  &:hover {
    color: #000 !important;
  }
}

.nav-colored {
  transition: 0.1s ease-in-out all;
  background-color: rgb(255, 255, 255);
  width: 100%;
  padding: 1% 5%;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.055), 0 10px 10px rgba(0, 0, 0, 0.055);
  .navbar_logo {
    img {
      max-height: 35px;
      margin-top: 1rem;
      max-width: auto;
    }
  }
}

.nav-transparent {
  height: 75px;

  background-color: white;
}

.btn-navbar {
  padding: 0.3rem 0.9rem;
  color: white !important;
  background-color: $app-1;

  border-radius: 6px;
  letter-spacing: 0.3px;
  font-weight: 400;
  &:hover {
    transition: all 0.2s ease-in-out;
    color: $app-1 !important;

    background-color: $app-2;
  }
}
